.invoice {
    background-color: #ffffff;
    padding: 20px;
  }
  
  .invoice > h1 {
    text-align: right;
    font-size: 20px;
    /* margin-top: 10; */
    font-weight: 800;
  }
  
  .divstyle1 {
    display: flex !important;
  }
  
  .imageContain {
    padding-bottom: 50px;
  }
  .divstyle2 {
    width: 100% !important;
    text-align: right;
  }
  
  .divstyle2 h4 {
    font-size: 14px;
  }
  
  .divstyle2 h5 {
    font-size: 10px;
  }
  .divstyle33{
    width: 100% !important;
    text-align: center;
  }
  .divstyle3 h1 {
    font-size: 22px;
    font-weight: 800;
  }
  
  .divstyle3 {
    display: flex;
    justify-content: space-between;
  }
  
  .divstyle4 table {
    padding: 10px !important;
    border: 2px solid #f3f2f8;
  }
  
  .divStyle5 {
    display: flex;
    justify-content: space-between;
  }
  .divstyle5 .textContainer {
    width: 500px;
  }
  
  .amountSummary {
    width: 350px;
    justify-content: right;
  }
  
  .amountSummary .heading {
    text-align: center;
  }
  
  .divStyle6 {
    display: flex;
    justify-content: space-between;
  }
  
  .divStyle6 h5 {
    font-size: 12px;
  }
  
  /* .table tr:nth-of-type(odd) {
    background-color: #f3f2f8;
  } */
  
  /* .table tr {
    height: 20px;
  } */
  
  .table td {
    border: 1px solid #d0d0d3;
    padding: 5px;
  }
  .table tr:nth-of-type(odd) {
    background-color: #f3f2f8;
    padding: 5px;
  }
  
  .table td {
    border: 1px solid #d0d0d3;
  }